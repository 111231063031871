import {useState, useContext, useEffect, useRef} from "react";
import {AppState} from "lib/context/AppProvider";
import {get} from "lib/helpers/api";
import {flatten} from "lodash";
import toast from "react-hot-toast";
import {speak} from "lib/helpers/globeview/useUtilities";
import {supportedSat} from "lib/helpers/utilities.js";
import {processOverPasses} from "components/GroundStationTable";

const fiveMinsInS = 300;

/**
 * Retrieves all telemetry data for selected satellite
 * @param {String} startTime, start time for overpass telemetry
 * @param {String} endTime, end time for overpass telemetry
 * @return {Object} with all selected satellite data
 */
const useGetAllSatelliteData = (startTime, endTime) => {
    const {store} = useContext(AppState);
    const {
        setSatellites,
        satellites,
        telemUpdated,
        setTelemUpdated,
        setSelectedSatIndex,
        selectedSatIndex,
        audioAlert,
        audioNewTelem,
        telemType,
        user,
        setConstellationDirty,
    } = store;
    const {accessToken} = user;
    const [loading, setLoading] = useState(false);

    /**
     * Queries the AST Telemtry API for telemetry data
     * @param {String} query, the query to send to the API
     * @param {String} baseURL, the base URL to send the query to
     * @return {Object} the response from the API
     */
    const sendQueryAST = async (query, baseURL = process.env.REACT_APP_AST_TELEMETRY_API_BASE_URL) => {
        if (!accessToken) return [];

        if (!user.isAST) { // don't hit AST API with saberdev login
            return [];
        }

        console.log("Running AST query: " + baseURL + query);
        const s = Date.now();

        try {
            const response = await get(baseURL + query, accessToken);
            console.log("  AST query succeeded:", query, "in", (Date.now() - s)/1000, "seconds");
            return response;
        } catch (error) {
            // throw new Error("AST telemetry query failed: " + error);
            console.error(`AST telemetry query to ${baseURL + query} failed:`, error?.response?.data?.message ?? error);
            return [];
        }
    };

    /**
     * Queries the API for telemetry data
     * @param {String} query, the query to send to the API
     * @param {Function} thenFunc, the function to run after the query is sent
     * @return {Object} the response from the API
     */
    const sendQuery = async (query, thenFunc = (response) => response) => {
        if (!accessToken) return [];

        if (user.isAST) {
            return [];
        }

        try {
            const response = await get(query, accessToken);
            return thenFunc(response);
        } catch (error) {
            throw new Error("Telemetry query failed: " + error);
        }
    };

    /**
     * Returns the latest telemetry object by observed_at
     * @param {Object} t1 first telemetry object
     * @param {Object} t2 second telemetry object to compare to
     * @return {Object} the latest telemetry object
     */
    const getLatestTelem = (t1, t2) => {
        // returns latest telem
        if (t1 && t2) {
            if (t1 && t1.observed_at && t2 && t2.observed_at) {
                if (
                    new Date(t1.observed_at).getTime() < new Date(t2.observed_at).getTime()
                ) {
                    return t2; // t2 telem is newer
                }
            } else if (t2.observed_at) {
                return t2; // t1 observed_at does not exist but t2 does
            }
            return t1;
        } else if (t2) {
            return t2; // t1 does not exist but t2 does
        }
        return t1;
    };

    // All functions that retrieve telemetry (telemetry is queried every 10s)

    /**
     * @param {String} str string to search in
     * @param {String} find string to find
     * @return {boolean} whether found in string (case insensitive)
     */
    const includesCI = (str, find) => {
        return str && find && str.toLowerCase().includes(find.toLowerCase());
    };

    const getASTTicketsStatus = async (status) => {
        const baseURL = process.env.REACT_APP_AST_SABER_HUB_BASE_URL;
        const query = `/trouble-tickets?ticket_status=${status}`;
        return sendQueryAST(query, baseURL);
    };

    const getASTTickets = async () => {
        const query1 = getASTTicketsStatus("UNACKNOWLEDGED");
        const query2 = getASTTicketsStatus("IN_PROGRESS");

        const r1 = await query1;
        const r2 = await query2;

        const tickets = [];

        if (r1 && r1.response) {
            tickets.push(...r1.response);
        }
        if (r2 && r2.response) {
            tickets.push(...r2.response);
        }
        return tickets;
    };

    /**
     * Gets Quindar ticket status for all satellites
     * @param {Array} satellites, all satellites to update
     */
    const updateTicketsAST = async (satellites) => {
        const tickets = await getASTTickets();
        for (const sat of satellites) {
            const satTickets = tickets.filter((ticket) =>
                includesCI(ticket.summary, sat.sat_id) || includesCI(ticket.description, sat.sat_id));
            sat.open_tickets = satTickets;
            sat.open_tickets.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        }
    };

    /**
     * Get's latest APC and FC telemetry through AST telemetry API
     * @param {Object} sat, the satellite object
     * @param {String} hardware, YP or YM
     * @return {Promise<Array>} an array of APC and FC telemetry
     */
    const apcFcTelemAST = async (sat, hardware) => {
        const satId = sat.sat_id;

        const subsystem = "ALL";
        const stack = hardware;

        const query = `/influx/telemetry/${satId}/${subsystem}/${stack}`;
        const telem = await sendQueryAST(query);
        return [{"telemetry": telem}];
    };

    /**
     * Get's latest APC and FC telemetry
     * @param {Object} sat, the satellite object
     * @param {String} hardware, YP or YM
     * @param {Boolean} isAST, whether to use AST telemetry API or Saber
     * @return {Promise<Array>} an array of APC and FC telemetry
     */
    const apcFcTelem = async (sat, hardware, isAST) => {
        if (isAST) {
            return await apcFcTelemAST(sat, hardware);
        }

        const configureTelem = (queries) => {
            return queries.map((q) =>
                q
                    .replace("<SATNO>", sat.norad_id)
                    .replace("<HARDWARE>", hardware),
            );
        };
        const promises = await configureTelem([
            "/satellite/<SATNO>/latesttelemetry"
                + "?component=APC_<HARDWARE>"
                + "&tlm_name=SC_MODE"
                + "&tlm_name=ADCS_MODE"
                + "&tlm_name=BOOTCOUNT"
                + "&tlm_name=LVC_BOOT_COUNT"
                + "&tlm_name=AGG1_BOARD_TEMP"
                + "&tlm_name=AGG2_BOARD_TEMP"
                + "&tlm_name=UHF_GS_WDT_COUNTER"
                + "&tlm_name=SBAND_GWD_COUNTER"
                // + "&tlm_name=PCDU_MPPT_BUSBAR_MEDIAN"
                + "&tlm_name=SOC_BATTERY1_VOLTAGE_MEDIAN"
                + "&tlm_name=SOC_BATTERY2_VOLTAGE_MEDIAN"
                + "&tlm_name=SOC_BATTERY1AND2_TOTAL"
                + "&tlm_name=SOC_BATTERY1AND2_TOTAL_PERCENT"
                + "&tlm_name=PCDU_BATT_ADC_THERM1"
                + "&tlm_name=PCDU_BATT_ADC_THERM2"
                + "&tlm_name=PCDU_BATT_ADC_THERM3"
                + "&tlm_name=PCDU_BATT_ADC_THERM4"
                + "&tlm_name=PCDU_MICRON_ADC_THERM1"
                + "&tlm_name=PCDU_MICRON_ADC_THERM2"
                + "&tlm_name=PCDU_MPPT_SWITCH_ADC_THERM1"
                + "&tlm_name=PCDU_MPPT_SWITCH_ADC_THERM2"
                + "&tlm_name=LVC_WDT_TIME_LEFT"
                + "&tlm_name=UHF_GS_WDT_TIME_LEFT_IN_SEC"
                + "&tlm_name=TIME_SINCE_LAST_COMM_IN_SEC"
                // + "&tlm_name=QVA_YP_STATUS_CURRENT_STATE"
                + "&tlm_name=QVA_YM_STATUS_CURRENT_STATE"
                + "&tlm_name=CSP_CMD_REC_COUNTER"
                + "&tlm_name=APC_BOARD_TEMP"
                + "&tlm_name=FC_BOARD_TEMP"
                + "&tlm_name=GPS_BOARD_TEMP"
                + "&tlm_name=PCDU_BATT_BFCP_XP"
                + "&tlm_name=PCDU_BATT_BFCP_XM",
            "/satellite/<SATNO>/latesttelemetry"
                + "?component=FC_<HARDWARE>"
                + "&tlm_name=AOCS_ACSUBMODE_ACTIVE"
                + "&tlm_name=AOCS_H_B_TOTAL"
                + "&tlm_name=BOOTCOUNT"
                + "&tlm_name=GPS_FIX"
                + "&tlm_name=RWA_YM0_Z_MEASSPEED"
                + "&tlm_name=RWA_YM1_X_MEASSPEED"
                + "&tlm_name=RWA_YP0_Z_MEASSPEED"
                + "&tlm_name=RWA_YP1_X_MEASSPEED"
                + "&tlm_name=AOCS_TH_B_ERROR_0_DEG"
                + "&tlm_name=AOCS_TH_B_ERROR_1_DEG"
                + "&tlm_name=AOCS_TH_B_ERROR_2_DEG"
                + "&tlm_name=AOCS_WBI_B_SV_ACTIVE_0_DEG_S"
                + "&tlm_name=AOCS_WBI_B_SV_ACTIVE_1_DEG_S"
                + "&tlm_name=AOCS_WBI_B_SV_ACTIVE_2_DEG_S"
                + "&tlm_name=CSP_CMD_REC_COUNTER"
                + "&tlm_name=GPS_FIX"
                + "&tlm_name=GPS_x0"
                + "&tlm_name=GPS_x1"
                + "&tlm_name=GPS_x2"
                + "&tlm_name=GPS_v0"
                + "&tlm_name=GPS_v1"
                + "&tlm_name=GPS_v2"
                + "&tlm_name=GPS_TIME_SEC"
                + "&tlm_name=AOCS_ECLIPSEFLAG"
                + "&tlm_name=AOCS_BETA_ANGLE_DEG"
                + "&tlm_name=ST_MODE_XM"
                + "&tlm_name=ST_MODE_XP"
                + "&tlm_name=AOCS_ORBIT_SEED_TIMER",
        ]).map(async (query) => sendQuery(query));
        const telem = await Promise.all(promises);

        if (telem.length > 0) {
            if (telem[0]) {
                telem[0].telemetry.forEach((t) => {
                    t.component = `APC_${hardware}`;
                });
            }
            if (telem[1]) {
                telem[1].telemetry.forEach((t) => {
                    t.component = `FC_${hardware}`;
                });
            }
        }
        return telem;
    };

    /**
     * Gets latest APC and FC telemetry
     * @param {Object} sat, the satellite object
     * @param {String} telemType, the telemetry stack type
     * @param {Boolean} isAST, whether to use the AST Telemetry API
     * @return {Array} an array of APC and FC telemetry
     */
    const getApcFcTelem = async (sat, telemType, isAST) => {
        if (telemType === "YP") {
            return await apcFcTelem(sat, "YP", isAST);
        } else if (telemType === "YM") {
            return await apcFcTelem(sat, "YM", isAST);
        } else if (isAST) {
            return await apcFcTelem(sat, "AUTOMATIC", isAST);
        }

        const telemYp = await apcFcTelem(sat, "YP", isAST);
        const telemYm = await apcFcTelem(sat, "YM", isAST);
        let telem = [];
        if (telemYp.length > 0 && telemYm.length > 0) {
            Object.assign(telem, telemYp);
            // compare APC and FC
            for (let i = 0; i < telem.length; i++) {
                telem[i].telemetry.forEach((t, j) => {
                    telem[i].telemetry[j] = getLatestTelem(
                        telemYp[i].telemetry[j],
                        telemYm[i].telemetry[j],
                    );
                });
            }
        } else if (telemYm.length > 0) {
            telem = telemYm;
        } else {
            telem = telemYp;
        }
        return telem;
    };

    /**
     * Gets the latest power mode micron telemetry
     * @param {Object} sat, the satellite object
     * @return {Array} a sorted array of power mode micron telemetry
     */
    const powModeMicronTelem = async (sat) => {
        const powModeTelem = await sendQuery(
            `/satellite/${sat.norad_id}/latesttelemetry`
                + "?packet=MIC_GET_SYS_PWR_MODE_RES&tlm_name=MIC_CURRENT_SYSTEM_POWER_MODE",
        );
        if (!powModeTelem || powModeTelem.length === 0) return [];

        const powModeTelemFrmt = powModeTelem.telemetry.map((t) => {
            return {
                component: "",
                observed_at: t.observed_at ? t.observed_at + "Z" : null,
                tlm_id: t.tlm_id,
                tlm_name:
                    "MICRON_" + t.tlm_id.replace(/\D/g, "") + "_POWER_MODE",
                value: t.value,
            };
        });
        return powModeTelemFrmt.sort(
            (a, b) =>
                parseInt(a.tlm_id.replace(/\D/g, ""))
                - parseInt(b.tlm_id.replace(/\D/g, "")),
        );
    };

    /**
     * Gets the latest SoC micron telemetry
     * @param {Object} sat, the satellite object
     * @return {Array} a sorted array of power mode micron telemetry
     */
    const socMicronTelem = async (sat) => {
        const socTelem = await sendQuery(
            `/satellite/${sat.norad_id}/latesttelemetry`
                + "?packet=MIC_EPS_TLM&tlm_name=MIC_DERIVED_BATT_STR_A_SOC&tlm_name=MIC_DERIVED_BATT_STR_B_SOC",
        );
        if (!socTelem || socTelem.length === 0) return [];

        const socTelemCat = {};

        socTelem.telemetry.map((t) => {
            const telemName = "MICRON_" + t.tlm_id.replace(/\D/g, "") + "_SOC";
            if (!socTelemCat[telemName]) {
                socTelemCat[telemName] = {
                    component: "",
                    observed_at: t.observed_at ? t.observed_at + "Z" : null,
                    tlm_id: t.tlm_id,
                    tlm_name: telemName,
                    value: [null, null],
                };
            }
            if (t.tlm_name === "MIC_DERIVED_BATT_STR_A_SOC") {
                socTelemCat[telemName].value[0] = t.value;
            } else if (t.tlm_name === "MIC_DERIVED_BATT_STR_B_SOC") {
                socTelemCat[telemName].value[1] = t.value;
            }
        });

        const socTelemFrmt = Object.values(socTelemCat);

        return socTelemFrmt.sort(
            (a, b) =>
                parseInt(a.tlm_id.replace(/\D/g, ""))
                - parseInt(b.tlm_id.replace(/\D/g, "")),
        );
    };

    /**
     * Gets the latest attitude telemetry
     * @param {Object} sat, the satellite object
     * @param {String} telemType, the type of telemetry to get
     * @return {Object} key value pair: rpyVals and rpyTelem (formatted for telem table)
     */
    const attitudeTelem = async (sat, telemType) => {
        const parseRpy = (t, stack) => {
            if (t.rpy) {
                t.rpy.observed_at = t.rpy.observed_at + "Z";
                t.rpy.component = `FC_${stack}`;
                return t.rpy;
            }
            return {
                observed_at: null,
                component: `FC_${stack}`,
            };
        };
        const queryAttitude = (sat, stack) => {
            return sendQuery(
                `/satellite/${sat.norad_id}/attitude?component=FC_${stack}`,
                (t) => parseRpy(t, stack),
            );
        };
        let rpyTelem;
        if (telemType === "Latest") {
            const rpyYp = await queryAttitude(sat, "YP");
            const rpyYm = await queryAttitude(sat, "YM");
            rpyTelem = getLatestTelem(rpyYp, rpyYm);
        } else if (telemType === "YP") {
            rpyTelem = await queryAttitude(sat, "YP");
        } else {
            rpyTelem = await queryAttitude(sat, "YM");
        }
        const frmtRpyTelem = () => {
            let rpyVals;
            if (
                sat.attitude
                && sat.attitude[0]
                && new Date(sat.attitude[0].observed_at).getTime
                    === new Date(rpyTelem.observed_at).getTime
            ) {
                rpyVals = sat.attitude;
            } else {
                rpyVals = rpyTelem
                    ? [rpyTelem.roll, rpyTelem.pitch, rpyTelem.yaw]
                    : [null, null, null];
            }
            return [
                {
                    tlm_id: "ROLL_DEG",
                    tlm_name: "ROLL_DEG",
                    component: rpyTelem.component,
                    observed_at: rpyTelem.observed_at,
                    value: rpyVals[0],
                },
                {
                    tlm_id: "PITCH_DEG",
                    tlm_name: "PITCH_DEG",
                    component: rpyTelem.component,
                    observed_at: rpyTelem.observed_at,
                    value: rpyVals[1],
                },
                {
                    tlm_id: "YAW_DEG",
                    tlm_name: "YAW_DEG",
                    component: rpyTelem.component,
                    observed_at: rpyTelem.observed_at,
                    value: rpyVals[2],
                }, // correct for coordinate system
            ];
        };

        return {
            rpyVals: rpyTelem,
            rpyTelem: frmtRpyTelem(),
        };
    };

    const findTelemRow = (telem, name) => {
        return telem.find((row) => row["tlm_name"] === name);
    };

    const extractTelemRPY = (telem) => {
        return {
            "roll": findTelemRow(telem, "ROLL_DEG")?.value,
            "pitch": findTelemRow(telem, "PITCH_DEG")?.value,
            "yaw": findTelemRow(telem, "YAW_DEG")?.value,
        };
    };

    const extractTelemFDIR = (telem) => {
        const tlm = findTelemRow(telem, "ADM_PACKET");
        if (!tlm) {
            return null;
        }

        // const types = ["NOT_MANAGED_OR_INACTIVE", "OK", "YELLOW", "RED"];
        const counters = ["TOTAL", "AOCS", "CDH", "EPS", "PRO", "QV", "THR", "TTC"];
        const fdir = {};

        for (const counter of counters) {
            fdir[counter] = {
                "NOT_MANAGED_OR_INACTIVE": 0,
                "OK": 0,
                "YELLOW": 0,
                "RED": 0,
            };
        }

        const payload = JSON.parse(tlm.value);
        if ("FDIR_RED_TRIGGER_COUNTER_SUM" in payload) {
            fdir["TOTAL"]["RED"] = payload["FDIR_RED_TRIGGER_COUNTER_SUM"];
        }
        if ("FDIR_YELLOW_TRIGGER_COUNTER_SUM" in payload) {
            fdir["TOTAL"]["YELLOW"] = payload["FDIR_YELLOW_TRIGGER_COUNTER_SUM"];
        }

        // for (const counter of counters) {
        //     for (const key in payload) {
        //         if (key.startsWith(counter + "_")) {
        //             const val = payload[key];
        //             if (val >= 0 && val < types.length) {
        //                 fdir[counter][types[val]]++;
        //                 fdir["TOTAL"][types[val]]++;
        //             }
        //         }
        //     }
        // }

        fdir.tlm_id = tlm.tlm_id;
        fdir.component = tlm.component;
        fdir.packet_name = tlm.packet_name;
        fdir.observed_at = tlm.observed_at;

        return fdir;
    };

    const replaceOrAdd = (array, tlm) => {
        if (!array) {
            array = [];
        }
        const index = array.findIndex((t) =>
            t.component === tlm.component && t.packet_name === tlm.packet_name
            && t.tlm_id === tlm.tlm_id && t.tlm_name === tlm.tlm_name);
        if (index >= 0) {
            array[index] = tlm;
        } else array.push(tlm);
        return array;
    };

    /**
     * Retrieves minimal telemetry for constellation view for all satellites
     * @param {Array} satellites to update
     * @return {Object} satellite object with telemetry added
     */
    const getMinimalTelemAST = async (satellites) => {
        if (!accessToken || satellites.length === 0 || !user.isAST) return satellites;

        const queries = [];

        for (const sat of satellites) {
            const satId = sat.sat_id;

            const subsystem = "CONSTELLATION";
            const stack = (telemType === "YP" || telemType === "YM") ? telemType : "AUTOMATIC";

            const query = `/influx/telemetry/${satId}/${subsystem}/${stack}`;
            const queryPromise = sendQueryAST(query);
            queries.push(queryPromise);
        }

        for (let i = 0; i < satellites.length; i++) {
            const sat = satellites[i];
            const telem = await queries[i];

            for (const tlm of telem) {
                sat.telemetry = replaceOrAdd(sat.telemetry, tlm);
            }

            const fdir = extractTelemFDIR(telem);
            if (fdir) {
                sat.fdir = fdir;
            }
        }

        setConstellationDirty(true);

        return satellites;
    };

    const isGettingTelem = useRef(false);

    /**
     * Compiles all telemetry for the selected satellite
     * @return {Object} satellite object with telemetry added
     */
    const getTelem = async () => {
        if (!accessToken || satellites.length === 0) return [];

        isGettingTelem.current = true;

        // TODO: update telemetry for all satellites in constellation (index not in hiddenSatellites)?

        const sat = satellites[selectedSatIndex];
        let telem = await getApcFcTelem(sat, telemType, user.isAST);

        if (telem.length > 0) {
            telem = telem.splice(0, 2);
            telem = flatten(telem.map((t) => t.telemetry));
            telem = telem.filter((row) => row);

            if (!user.isAST) {
                const attitude = await attitudeTelem(sat, telemType);
                sat.attitude = attitude?.rpyVals;
                const attitudeFrmt = attitude?.rpyTelem;

                telem.push(...attitudeFrmt);
                const powModeMicronTelemRes = await powModeMicronTelem(sat);
                telem.push(...powModeMicronTelemRes);
                const socMicronTelemRes = await socMicronTelem(sat);
                telem.push(...socMicronTelemRes);
            } else {
                const rpy = extractTelemRPY(telem);
                if (rpy.roll && rpy.pitch && rpy.yaw) {
                    sat.attitude = rpy;
                } else {
                    sat.attitude = {roll: 0, pitch: 0, yaw: 0};
                }

                const fdir = extractTelemFDIR(telem);
                if (fdir) {
                    sat.fdir = fdir;
                }
            }

            const latestTelem = new Date(
                Math.max.apply(
                    null,
                    telem.map((t) => {
                        return t?.observed_at
                            ? new Date(t.observed_at + "Z")
                            : null;
                    }),
                ),
            );

            sat.telemetry = telem;

            if (!telemUpdated && latestTelem) {
                setTelemUpdated(latestTelem); // First run, no alert notifications
            } else if (
                latestTelem
                && telemUpdated.getTime() < latestTelem.getTime()
            ) {
                // Only alert if telem has been dirty for 5m or more.
                if ((new Date() - telemUpdated) / 1000 > fiveMinsInS) {
                    if (audioNewTelem && audioAlert && speechSynthesis.getVoices().length > 0) {
                        speak("New Telemetry");
                    }
                    toast("New Telemetry", {
                        position: "bottom-left",
                        duration: 2000,
                    });
                }
                setTelemUpdated(latestTelem);
            }
        }

        isGettingTelem.current = false;

        return satellites;
    };

    const formatASTContacts = (sat, contacts) => {
        for (const contact of contacts) {
            contact.sat_id = sat.sat_id; // contacts only provide sat UUID
            if (contact.gs_id in sat.groundstations) {
                contact.gs_name = sat.groundstations[contact.gs_id].gs_name;
            }
        }
        return processOverPasses(contacts);
    };

    const setSatGroundstations = (sat, groundstations) => {
        const gs = {};
        for (const s of groundstations) {
            gs[s.gs_id] = s;
        }
        sat.groundstations = gs;
    };

    const getStaticSatInfoAST = async (startTime, endTime) => {
        console.log("Getting static sat info...");
        if (!accessToken) {
            console.log("No access token!");
            return [];
        }
        if (satellites.length === 0) {
            console.log("No satellites!");
            return [];
        }

        const baseURL = process.env.REACT_APP_AST_TELEMETRY_API_BASE_URL;// process.env.REACT_APP_AST_SABER_HUB_BASE_URL;

        const queries = [];

        for (let i = 0; i < satellites.length; i++) {
            const sat = satellites[i];

            // const query = `/ground-overpasses/${sat.sat_id}`;
            const query = `/contacts/${sat.sat_id}?start_minutes=10`;
            const queryPromise = sendQueryAST(query, baseURL);
            queries.push(queryPromise);
        }

        for (let i = 0; i < satellites.length; i++) {
            const sat = satellites[i];
            const response = await queries[i];

            // set ground stations
            // TODO: disabled since we get stations from catalog call now; check if works with overpasses
            // if (response?.ground_stations) {
            //     sat.groundstations = response.ground_stations;
            // }
            // if (response?.ground_stations) {
            //     const stations = sat.groundstations;
            //     for (const station of response.ground_stations) {
            //         if (!(station.gs_id in stations)) {
            //             stations[station.gs_id] = station;
            //         }
            //     }
            //     sat.groundstations = stations;
            // }

            // set overpasses
            if (response?.overpasses) {
                sat.overpasses = formatASTContacts(sat, response.overpasses);
            }
        }

        return satellites;
    };

    /**
     * get all static (or relatively static) satellite info
     * @param {String} startTime, start time for overpass telemetry
     * @param {String} endTime, end time for overpass telemetry
     * @return {Object} satellite object with static info added
     */
    const getStaticSatInfo = async (startTime, endTime) => {
        if (!accessToken || satellites.length === 0) return [];

        if (user.isAST) {
            return getStaticSatInfoAST(startTime, endTime);
        }

        // TODO: shouldn't this update all the satellites, not just selected?

        const sat = satellites[selectedSatIndex];
        const now = new Date().toISOString().slice(0, -5);
        const tomorrow = new Date(Date.now() + 86400000)
            .toISOString()
            .slice(0, -5);
        const timeParams
            = startTime && endTime
                ? `&end_time=${endTime}&start_time=${startTime}`
                : `&start_time=${now}&end_time=${tomorrow}`;

        // get tle
        sat.tle = await sendQuery(`/tle/${sat.norad_id}`);

        // get overpasses
        sat.overpasses = await sendQuery(
            `/overpass?sat_id=${sat.sat_id}` + timeParams,
        );
        if (sat.overpasses) {
            sat.overpasses = processOverPasses(sat.overpasses);
        }

        // get ground stations
        const groundstations = await sendQuery("/groundstation");
        setSatGroundstations(sat, groundstations.gs_results);

        return satellites;
    };

    /**
     * Get All available Satellites
     * @return {Array} array of available satellite objects
     */
    const getSatellites = async () => {
        if (!accessToken || satellites.length > 0) return [];

        const data = {
            satellites: [],
        };

        if (user.isAST) {
            // Get satellites from AST
            data.satellites = await sendQueryAST("/catalog");// , process.env.REACT_APP_AST_SABER_HUB_BASE_URL);
            if ("station_results" in data.satellites) {
                for (const sat of data.satellites.sat_results) {
                    setSatGroundstations(sat, data.satellites.station_results);
                }
            }
        } else {
            // Get satellites from Saber
            data.satellites = await sendQuery("/satellite");
        }

        data.satellites = data.satellites.sat_results;

        return data.satellites;
    };

    // Get all available satellites (only on load)
    useEffect(() => {
        const getSats = async () => {
            if (satellites && satellites.length > 0) return; // only run once
            setLoading(true);
            try {
                const data = await getSatellites();
                if (data && data.length > 0) {
                    setSatellites(data);
                    setSelectedSatIndex(0);

                    if (user.isAST) {
                        getMinimalTelemAST(data);
                        updateTicketsAST(data);
                    }
                }
            } finally {
                setLoading(false);
            }
        };
        getSats().catch((e) => console.log(e));
    }, [accessToken]);

    let interval;
    let retryInterval;
    // Get all static satellite info (every 10 min)
    // if tle or overpasses is missing retry every 5 seconds
    useEffect(() => {
        const getSatelliteInfo = async () => {
            setLoading(true);
            const telemPromise = getTelem();
            try {
                const data = await getStaticSatInfo(startTime, endTime);
                if (data.length > 0) {
                    setSatellites(data);

                    setSatellites(await telemPromise);

                    const noStaticData = !data[selectedSatIndex]?.tle || !data[selectedSatIndex]?.overpasses;
                    const newIntervalTime = noStaticData ? 60000 : 600000; // 1 minute or 10 minutes in milliseconds
                    clearInterval(interval);
                    interval = setInterval(getSatelliteInfo, newIntervalTime);
                    if (noStaticData) {
                        clearInterval(retryInterval);
                        retryInterval = setInterval(getSatelliteInfo, 5000); // Retry every 5 seconds if missing data
                    } else {
                        clearInterval(retryInterval);
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        };

        if (supportedSat(satellites, selectedSatIndex)) {
            getSatelliteInfo();
        } else if (satellites && satellites.length > 0) {
            console.error("Not a supported satellite! Is the NORAD ID misconfigured?", satellites, selectedSatIndex);
        }

        return () => {
            clearInterval(interval);
            clearInterval(retryInterval);
        };
    }, [selectedSatIndex, accessToken, startTime, endTime]);

    // Get all telemetry (every 10s)
    useEffect(() => {
        const getTelemetry = async () => {
            setLoading(true);
            try {
                const newData = await getTelem();
                setSatellites(newData);
            } finally {
                setLoading(false);
            }
        };

        const onRepeat = () => {
            getTelemetry().catch();
            if (user.isAST) {
                // this already does it for all satellites
                getMinimalTelemAST(satellites);
                updateTicketsAST(satellites);
            }
        };

        onRepeat();

        if (!supportedSat(satellites, selectedSatIndex)) return;
        if (!isGettingTelem.current) {
            getTelemetry().catch();
        }

        const interval = setInterval(onRepeat, 60000); // 1 minute in milliseconds

        return () => clearInterval(interval);
    }, [telemType, selectedSatIndex, accessToken]);

    return {loading};
};

export default useGetAllSatelliteData;
