import {useState} from "react";

const useSatellites = () => {
    const [satellites, setSatellitesRaw] = useState([]);
    const [selectedSatIndex, setSelectedSatIndex] = useState(null);
    const [nextPass, setNextPass] = useState([]);
    const [previousPass, setPreviousPass] = useState([]);
    const [currentPass, setCurrentPass] = useState([]);
    const [telemQueries, setTelemQueries] = useState([]);
    const [telemUpdated, setTelemUpdated] = useState(null);
    const [block1Only, setBlock1OnlyRaw] = useState(localStorage.getItem("Block1Only") === "true");
    const [audioAlert, setAudioAlertRaw] = useState(localStorage.getItem("AudioAlert") !== "false");
    const [audioNewTelem, setAudioNewTelem] = useState(localStorage.getItem("AudioNewTelem") === "true");
    const [audioUpcomingPass, setAudioUpcomingPass] = useState(localStorage.getItem("AudioUpcomingPass") !== "false");
    const [audioPassStart, setAudioPassStart] = useState(localStorage.getItem("AudioPassStart") !== "false");


    const setAudioAlert = (v) => {
        setAudioAlertRaw(v);
        localStorage.setItem("AudioAlert", v);
    };

    const [telemType, setTelemType] = useState(localStorage.getItem("TelemType")
                                                ?? "Latest");
    const configureTelem = (satNo, queries)=>{
        setTelemQueries(queries.map((q)=>q.replace("<SATNO>", satNo)));
    };

    const setBlock1Only = (v) => {
        setBlock1OnlyRaw(v);
        window.location.reload();
    };

    return {
        satellites, setSatellitesRaw,
        nextPass, setNextPass,
        currentPass, setCurrentPass,
        telemQueries, configureTelem,
        selectedSatIndex, setSelectedSatIndex,
        telemUpdated, setTelemUpdated,
        previousPass, setPreviousPass,
        audioAlert, setAudioAlert,
        telemType, setTelemType,
        block1Only, setBlock1Only,
        audioNewTelem, setAudioNewTelem,
        audioUpcomingPass, setAudioUpcomingPass,
        audioPassStart, setAudioPassStart,
    };
};

export default useSatellites;
