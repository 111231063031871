import React, {useState, createContext, useEffect} from "react";
import AuthProvider from "lib/context/AuthProvider";
import useUser from "lib/context/states/useUser";
import useSatellites from "lib/context/states/useSatellites";
import PropTypes from "prop-types";

let timerEvent;

export const AppState = createContext();

export const AppProvider = ({children}) => {
    const {user, setUsername, setAccessToken, setRefreshToken, logoutUser, setIsAST} = useUser();
    const {
        satellites, setSatellitesRaw,
        nextPass, setNextPass,
        previousPass, setPreviousPass,
        currentPass, setCurrentPass,
        selectedSatIndex, setSelectedSatIndex,
        telemUpdated, setTelemUpdated,
        audioAlert, setAudioAlert,
        telemType, setTelemType,
        block1Only, setBlock1Only,
        audioNewTelem, setAudioNewTelem,
        audioUpcomingPass, setAudioUpcomingPass,
        audioPassStart, setAudioPassStart,
    } = useSatellites();

    const setSatellites = (sats) => {
        setSatellitesRaw((user.isAST && block1Only) ? sats.filter((sat) => sat.sat_id?.startsWith("SM")) : sats);
    };

    const [showSatMenu, setShowSatMenu] = useState(false);

    const [isConstellationDirty, setConstellationDirty] = useState(false);

    const [currentTime, setCurrentTime] = useState(
        new Date(),
    );

    if (!timerEvent) {
        timerEvent = setInterval(()=>{
            const now = new Date();
            setCurrentTime(now);
        }, 5000);
    }

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                setCurrentTime(new Date());
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const store = {

        /* STATES */
        user,
        satellites,
        nextPass,
        currentPass,
        previousPass,
        currentTime,
        selectedSatIndex,
        telemUpdated,
        audioAlert,
        telemType,
        showSatMenu,
        isConstellationDirty,
        block1Only,
        audioNewTelem,
        audioUpcomingPass,
        audioPassStart,

        /* SETTERS */
        setAccessToken,
        setRefreshToken,
        setSatellites,
        setNextPass,
        setPreviousPass,
        setCurrentPass,
        setSelectedSatIndex,
        setUsername,
        setTelemUpdated,
        setAudioAlert,
        setTelemType,
        setIsAST,
        setShowSatMenu,
        setConstellationDirty,
        setBlock1Only,
        setAudioNewTelem,
        setAudioUpcomingPass,
        setAudioPassStart,

        /* EFFECTS */
        logoutUser,

    };

    return (
        <AppState.Provider value={{store}}>
            <AuthProvider>
                { children }
            </AuthProvider>
        </AppState.Provider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppProvider;
