import {useContext, useEffect, useRef, useState} from "react";
import {AppState} from "lib/context/AppProvider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import {getCurrentPassesAll, getNextPassAll} from "./GroundStationTable";
import {selectSat} from "./ConstellationGrid";
import {GlobeState} from "lib/context/GlobeProvider";
import {speak, toUtc} from "lib/helpers/globeview/useUtilities";
import toast from "react-hot-toast";

const speakableSatName = (satId) => {
    // assumption: letters followed by number
    // BW3, SM001
    let speak = "";
    const letterGroups = satId.match(/[a-zA-Z]+/g);
    if (letterGroups?.length > 0) {
        speak += letterGroups[0].split("").map((l) => `${l}. `).join("");
    }
    const numberGroups = satId.match(/\d+/g);
    if (numberGroups?.length > 0) {
        speak += parseInt(numberGroups[0]);
    }
    return speak.length === 0 ? satId : speak;
};

const speakableList = (list) => {
    return list.map((word, i) => list.length >= 2 && i === list.length-1 ? `and ${word}` : word).join(", ");
};

const passInList = (p, list) => {
    return list.find((p2) => p.sat_id === p2.sat_id && p.gs_name === p2.gs_name);
};

const Alerts = (props) => {
    const {store} = useContext(AppState);
    const {
        satellites,
        selectedSatIndex,
        currentTime,
        user,
        audioAlert,
        audioUpcomingPass,
        audioPassStart,
    } = store;
    const {height} = props;
    const isMobile = useMediaQuery({query: "(max-width: 1045px)"});
    const style = {
        bottom: 0,
        right: 0,
        height: height,
        minHeight: height,
        width: "fit-content",
        margin: "1rem",
        padding: "0.5rem 1rem",
    };

    const [currentPasses, setCurrentPasses] = useState(null);
    const [nextPass, setNextPass] = useState(null);
    const prevUpcoming = useRef(null);
    const prevNext = useRef([]);

    const queueSpeak = (message) => {
        if (audioAlert && speechSynthesis.getVoices().length > 0) {
            // TODO: check if speech is talking already
            // TODO: delay message until voices are ready
            speak(message);
        }
    };

    const queueToast = (message) => {
        toast(message, {
            position: "bottom-left",
            duration: 5000,
        });
    };

    const timeToAos = (pass) => {
        const aos = toUtc(pass.aos, true);
        return aos - currentTime;
    };

    useEffect(() => {
        // unclear, but it seems sometimes you need to call this once and get an empty array
        // in order for the voices to load
        speechSynthesis.getVoices();
    }, []);

    useEffect(() => {
        if (!satellites || satellites.length === 0) {
            setCurrentPasses(null);
            setNextPass(null);
            return;
        }

        const {nextPass, nextPasses} = getNextPassAll(satellites, currentTime);
        const current = getCurrentPassesAll(satellites, currentTime);

        const threshold = 63 * 1000; // one minute, but we tick in 5 second intervals
        const missedThreshold = 50 * 1000; // 50 seconds

        const upcoming = nextPasses.filter((p) => timeToAos(p) <= threshold);
        const newUpcoming = upcoming.filter((p) => !passInList(p, prevUpcoming.current));

        if (newUpcoming.length > 0) {
            newUpcoming.forEach((p) => queueToast(
                timeToAos(p) <= missedThreshold ? `Upcoming pass for ${p.sat_id}` : `One minute to ${p.sat_id} AOS`,
            ));

            if (audioUpcomingPass) {
                const names = speakableList(newUpcoming.map((p) => speakableSatName(p.sat_id)));
                const missed = newUpcoming.some((p) => timeToAos(p) <= missedThreshold);
                queueSpeak(
                    missed ? `Upcoming pass for ${names}` : `One minute to ${names} A O S`,
                );
            }
        }

        const newCurrent = current.filter((p) =>
            !passInList(p, nextPasses) && passInList(p, prevNext.current));

        if (newCurrent.length > 0) {
            newCurrent.forEach((p) => queueToast(`Pass starting for ${p.sat_id}`));

            if (audioPassStart) {
                const names = speakableList(newCurrent.map((p) => speakableSatName(p.sat_id)));
                queueSpeak(`Pass starting for ${names}`);
            }
        }

        prevUpcoming.current = upcoming;
        prevNext.current = nextPasses;

        setNextPass(nextPass);
        setCurrentPasses(current);
    }, [currentTime, selectedSatIndex, satellites]);

    const {globe} = useContext(GlobeState);
    const {
        setShowConstellationGrid,
        setShowOverpassTable,
        setShowAllContacts,
    } = globe;

    const handleClickNext = () => {
        if (!nextPass) {
            return;
        }
        selectSat(nextPass.sat_id, store);
        setShowConstellationGrid(false);
        setShowOverpassTable(true);
    };

    const handleClickCurrent = (satId) => {
        if (!satId) {
            return;
        }

        if (satId < 0) {
            setShowAllContacts(true);
        } else {
            selectSat(satId, store);
        }

        setShowConstellationGrid(false);
        setShowOverpassTable(true);
    };

    return (
        user.isLoggedIn
        && <div className="alerts p-1" style={style}>
            {isMobile && (
                <Row className="place-center">
                    <Col className="place-center">
                        <span className="clock">
                            {currentTime.toISOString().substring(0, 19) + "Z"}
                        </span>
                    </Col>
                </Row>
            )}

            {currentPasses && currentPasses.length > 0 && (
                (isMobile ? [currentPasses[0]] : currentPasses).map((currentPass) => (
                    <Row key={"currentPass"+currentPass.sat_id} className="place-center">
                        <Col className="place-center" onClick={() => handleClickCurrent(currentPass.sat_id)}
                            style={{cursor: "pointer", textAlign: "left"}}>
                            <b>In Pass ({currentPass.sat_id}): </b>
                            {isMobile ? (
                                <span><br />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            ) : (<span>&nbsp;</span>)}
                            {currentPass
                                && currentPass.gs_name
                                    + (currentPass.band !== "N/A"
                                        ? " (" + currentPass.band + ")"
                                        : "")}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;For {currentPass.timeUntilLos}
                        </Col>
                    </Row>
                ))
            )}
            {currentPasses && isMobile && currentPasses.length > 1 && (
                <Row className="place-center">
                    <Col className="place-center" onClick={() => handleClickCurrent(-1)}
                        style={{cursor: "pointer", textAlign: "left"}}>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;{`+ ${currentPasses.length-1} other${currentPasses.length-1 === 1 ? "" : "s"}`}</b>
                    </Col>
                </Row>
            )}
            {nextPass && nextPass.gs_name !== undefined && (
                <Row>
                    <Col className="place-center" onClick={handleClickNext} style={{cursor: "pointer", textAlign: "left"}}>
                        <b>Next Pass ({nextPass.sat_id}):</b>
                        {isMobile ? (
                            <span><br />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        ) : (<span>&nbsp;</span>)}
                        {nextPass.gs_name}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;In {nextPass.timeUntil}
                    </Col>
                </Row>
            )}
        </div>
    );
};

Alerts.propTypes = {
    height: PropTypes.number,
};

export default Alerts;
